<template>
    <div>
        <v-card class="primary darken-2" style="" >
            <v-card-text class="py-1 ma-0 " style="font-size: 12px; ">
                <p class="white--text py-1 ma-0 subheading text-xs-center"><span>Accepting Njangi Group Invitation</span></p>
            </v-card-text>
        </v-card>
        <v-divider></v-divider>

        <v-card color="white pa-3 pt-1" style="" >
            <v-layout row wrap class="">
                <v-flex xs12 sm7 class="pa-1">
                    <p class="dark--text subheading pa-0 ma-0 " blue>
                        <u><b>{{ njangi_group_name }}</b></u> 
                    </p>
                    <p class="dark--text caption pa-0 ma-0 " blue v-html="njangi_group_description"></p>
                </v-flex>
                <v-flex xs12 sm5 class="mt-0 text-xs-right" v-if="$store.state.isAuthenticated">
                    <v-btn @click="acceptNjangiGroup()" class="white--text" small round color="teal darken-2" >
                        <span> Accept Invitation</span>
                    </v-btn>
                </v-flex>
                <v-flex xs12 sm5 class="mt-0 text-xs-right" v-else>
                    <v-btn @click="acceptNjangiGroupLogin()" class="white--text" small round color="teal darken-2" :to="{name: 'login'}">
                        <span> Accept Invitation</span>
                    </v-btn>
                </v-flex>
            </v-layout>
            <v-card-text class="body-2 pa-2" style="font-size: 12px; padding: 5px">
                <v-layout row wrap class="">
                    <v-flex xs12 md6 class="pt-0 mt-0">
                        <span class="caption"> <b>Njangi Contact:</b> {{ njangi_contact }} </span>
                    </v-flex>
                    <v-flex xs12 md6 class="pt-0 mt-0">
                        <span class="caption"> <b>Contributions:</b>  {{ contribution_amount }} F CFA per person every {{ collecting_interval }} {{ collecting_cycle }} </span>
                    </v-flex>
                    <v-flex xs12 md6 class="pt-0 mt-0">
                        <span class="caption"><b>Account:</b> {{ amount_deducted }} F CFA saved into njangi account for every njangi meeting </span> 
                    </v-flex>
                    <v-flex xs12 md6 class="pt-0 mt-0">
                        <span class="caption"> <b>Collectors:</b> {{ no_people_collect_per_cycle }} people collect every {{ collecting_interval }} {{ collecting_cycle }} </span> 
                    </v-flex>
                </v-layout>
                <v-spacer></v-spacer>
            </v-card-text>
            <v-divider></v-divider>
        </v-card>
    </div>
</template>

<script>
    import axios from 'axios'

    export default {
        data() {
            return {
                njangi_invitation: '',
                njangi_group_name: '',
                njangi_group_description: '',
                njangi_contact: '',
                contribution_amount: '',
                collecting_interval: '',
                collecting_cycle: '',
                no_people_collect_per_cycle: '',
                amount_deducted: '',
                njangi_invitation_link: '',
            }
        },
        mounted(){
            // executes these after the page has been mounted
            document.title = "PettyCash | Njangi Invitation"
            this.getNjangiGroup()
        },

        methods:{
            async getNjangiGroup(){
                this.$store.commit('setIsLoading', true)
                const group_id = this.$route.params.new_group_id  // get group id from url and send request to server
            
                await axios
                    .get('/api/v1/njangi/get/user/groups/detail/'+group_id+'/')
                    .then(response => {
                        this.njangi_group_name = response.data.njangi_group_name
                        this.njangi_group_description = response.data.njangi_group_description
                        this.njangi_contact = response.data.njangi_contact
                        this.contribution_amount = response.data.contribution_amount
                        this.collecting_interval = response.data.collecting_interval
                        this.collecting_cycle = response.data.collecting_cycle
                        this.no_people_collect_per_cycle = response.data.no_people_collect_per_cycle
                        this.amount_deducted = response.data.amount_deducted
                        this.njangi_invitation = 'Hello 😃, join our njangi group '+ response.data.njangi_group_name+'. '+ response.data.njangi_group_description + '. %0a '+response.data.get_invite_link  // get the data and fill into campaigns
                        this.njangi_invitation_link = response.data.get_invite_link  // get the data and fill into campaigns
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            
            async acceptNjangiGroup(){
                this.$store.commit('setIsLoading', true)
                const group_id = this.$route.params.new_group_id  // get group id from url and send request to server
                await axios
                    .post('/api/v1/njangi/accept/invitation/'+group_id+'/')
                    .then(response => {
                        this.$store.commit('setSnackBarMessage', response.data[0].text)
                        this.$store.commit('setSnackBarColor', response.data[0].color)
                        this.$store.commit('activateSnackBar', true)
                        if(response.data[0].id !== 0){
                            let router = this.$router
                            setTimeout(function(){
                                router.push('/njangi/groups/detail/'+group_id+'/')
                            }, 4000)
                        }
                    })
                    .catch(error => {
                        if (error.response){
                            this.$store.commit('setSnackBarMessage', error.response.status + " " + error.response.statusText)
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }else{
                            this.$store.commit('setSnackBarMessage', "An error occured, please check your internet connection and try again later.")
                            this.$store.commit('setSnackBarColor', "red darken-4")
                            this.$store.commit('activateSnackBar', true)
                        }
                    })
                this.$store.commit('setIsLoading', false)
            },
            acceptNjangiGroupLogin(){
                const group_id = this.$route.params.new_group_id  // get group id from url and send request to server
                this.$store.commit('acceptedNjangiGroupInvitation', group_id)
                console.log(this.$store.state.acceptedNjangiGroup, this.$store.state.acceptedNjangiGroupId)
            },
        },
    }
</script>
